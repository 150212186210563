html, body  {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
	background: #0f0f23;
	color: #cccccc;
	font-family: "Source Code Pro", monospace;
	font-weight: 300;
}

#root {
	margin: 3rem;
}


/* Container */
.container, .container > div {
	display: flex;
	overflow-x: hidden;
	overflow-y: auto;
}

.container {
	flex-direction: column;
}

.top-container > div {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 3rem;
}

.left-container {
	flex: 3;
	margin-right: 3rem;
	overflow: auto;
}

.left-container > div {
	margin-bottom: 3rem;
}

.right-container {
	flex: 2;
}

/* Much wow, such responsive */
@media (max-width: 1200px) {
	.top-container {
		flex-direction: column;
	}
}
@media (max-width: 1608px) {
	.bottom-container {
		flex-direction: column;
	}

	.left-container {
		margin-right: 0;
		flex: auto;
	}

	.right-container {
		flex: auto;
	}
}


/* Headers */
h1 {
	font-size: 1.5rem;
	cursor: pointer;
}

h1 .medal {
	margin-bottom: -2px;
}

h2 {
	font-size: 1.25rem;
}

h3 {
	font-size: 1rem;
}


/* Table */
table, td, th {
	font-size: 1rem;
	white-space: nowrap;
	border: 1px solid #0f0f23;
	vertical-align: middle;
	background: #0f0f23;
	border-collapse: separate;
	border-spacing: 0;
}

td, th {
	height: 1.9375rem;
}

td {
	padding: 0 0.5rem;
}

td.right {
	text-align: right;
}

td.center {
	text-align: center;
}

.table-sm td, .table-sm th {
	padding: 0 0.375rem;
	height: 1.5rem;
	font-size: 0.75rem;
}


/* Table borders */
.table-container tr > td:nth-child(1) {
	border-left-color: #cccccc;
}

.table-container tr > td:nth-child(3),
.table-container tr > th:nth-child(3),
.table-container tr > th[rowspan]:nth-child(3),
.table-container table:not(.table-time) tr > td:nth-child(6),
.table-container tr > th:nth-child(2) + th:not([rowspan]),
.table-container tr > th[rowspan]:nth-child(5) + th,
.table-container tr > td:last-child,
.table-container tr > th:last-child {
	border-right-color: #cccccc;
}


/* Table stickiness */
.table-container {
	width: 100%;
	overflow: auto;
	padding-bottom: 1rem;
}

.sticky {
	position: -webkit-sticky;
	position: sticky;
	left: 0;
	z-index: 2;
}


/* Eye candy */
.star-gold {
	color: #ffff66;
}

.star-silver {
	color: #9999cc;
}

.medal {
	height: 1.25rem;
	width: 1.25rem;
	margin-top: 0.3rem;
}

.member {
	color: #009900;
}

.glow {
	color: #00cc00;
    text-shadow: 0 0 2px #00cc00, 0 0 5px #00cc00;
}

.warning {
	color: #ffaaaa;
}

.star {
	height: 1.5rem;
	width: 1.5em;
}

.not-solved {
	color: #ffaaaa;
	font-size: 0.75rem;
	padding-left: 1.5rem;
	text-shadow: 0 0 2px #ffaaaa, 0 0 5px #ffaaaa;
	vertical-align: middle;
}

.table-sm .not-solved {
	font-size: 0.5rem;
}

/* Tooltip */
.tooltip {
	background-color: #0f0f23;
	border: 0.5px solid #cccccc;
	color: #cccccc;
	display: flex;
	flex-direction: column;
	font-size: 7px;
	padding: 1px 4px 3px 4px;
	width: 50px;
}

.tooltip.tooltip-wide {
	width: 75px;
}

.tooltip .tooltip-container {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.tooltip .star-silver, .tooltip .star-gold {
	font-size: 9px;
	margin-left: 2px;
}

/* Legend */
.VictoryContainer > svg > g:nth-child(3) path, .VictoryContainer > svg > g:nth-child(3) text {
	cursor: pointer;
}

/* Loading */
.loading {
	margin-top: 2rem;
	text-align: center;
}

.ribbon {
	color: #cc0000;
    text-shadow: 0 0 4px #ff0000, 0 0 4px #ff0000;
}

/* Protip */
.protip {
	font-size: 0.75rem;
}